body {
    background: #F4F4F4;
    color: #333 !important;
    font-family: "Trebuchet MS", Arial, Helvetica, sans-serif !important;
    height: 100%;
}

h1, .h1 {
    font-size: 2.2rem;
    font-family: AntennaCond, Arial, Helvetica, sans-serif;
    font-weight: 500;
    margin-top: 0;
    margin-bottom: 0.25em !important;
}

.centered {
    margin: auto;
    text-align: center;
    width: 70%;
    padding: 20px 0;
}

.error-message {
    color: red;
}

.app-branding {
    color: #036;
    font-family: Antenna, sans-serif;
    font-size: 2.000em;
    font-weight: 700;
    height: 100px;
}

.table-container {
    margin: 2em;
}

.footer {
    background-color: #DDDDDD;
    background-image: linear-gradient(to bottom, #DDDDDD 0%,#FFFFFF 100%);
    clear: both;
    height: 5em;
    width: 100%;
    text-align: center;
    padding: 0.5em;
    bottom: 0;
    display: block;
}

.ui-menubar {
    padding: 0;
    background-image: url("../assets/images/gradient_texture.png");
    background-color: #004477;
    -webkit-box-shadow: 4px 4px 6px #999;
    -moz-box-shadow: 4px 4px 6px #999;
    box-shadow: 4px 4px 6px #999;
    width: auto;
    height:25px;
}

.search-filters {
    width: auto;
    margin: 1.5em;
    font-family: AntennaCond, Arial, Helvetica, sans-serif;
    font-weight: 500;
    display: block;
    text-align: left;
}

#panel {
    margin: 15px 20px;
    background-color: #fff;
    border: 1px solid #A6D3FB;
    border-radius: 4px;
    font-size: 12px;
    padding: 10px 15px;
    display: block;
}

.welcome {
    margin: 20px;
}

.dra-header{
    text-align: left;
}
